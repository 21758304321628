$(document).ready(function(){
    "use strict";


  // Cache the window object
  var $window = $(window);


    /* ---------------------------------------------
  Anfrage Hidden
  --------------------------------------------- */
  
  $('#anFrage').click(function(event) {
      event.preventDefault(); // yaa!
      var anfrage = $("#anFrage");
      $(".field, .card-image").addClass("opa-1").removeClass("hidden");
      anfrage.remove();
    });

 /* ---------------------------------------------
  Ajax - PHP Email send
  --------------------------------------------- */


    $(function() {
      $("#send").click(function() {
          var data = {
            vorname: $("#vorname").val(),
            nachname: $("#nachname").val(),
            email: $("#email").val(),
            telefon: $("#telefon").val(),
            message: $("#msg").val(),
            person: $("#person").val(),
            datum: $("#datum").val(),
            anlass: $("#anlass").val(),
            gast: $("#gast").val(),
            plz: $("#plz").val(),
            ort: $("#ort").val(),
            lokation: $("#lokation").val()
        };
        $.ajax({
            type: "POST",
            url: "email.php",
            data: data,
            success: function(){
              $('#notification-bar').text('Danke.. Sie sind schon ein schritt weiter.');
              setTimeout(
                function() 
                {
                  $('#notification-bar').remove();
                }, 3000);
             // Clear the form.
            $("#vorname").val(''),
            $("#nachname").val(''),
            $("#email").val(''),
            $("#telefon").val(''),
            $("#msg").val(''),
            $("#person").val(''),
            $("#datum").val(''),
            $("#anlass").val(''),
            $("#gast").val(''),
            $("#plz").val(''),
            $("#ort").val(''),
            $("#lokation").val('') 
            }
        });
  
          return false;
      });
  });





  /* ---------------------------------------------
  Parallax background effect
  --------------------------------------------- */


  $('header[data-type="background"], section[data-type="background"]').each(function() {

    var $bgobj = $(this); // assigning the object

    $window.scroll(function() {

      // scroll the background at var speed
      // the yPos is a negative value because we're scrolling it UP!

      var yPos = -($window.scrollTop() / $bgobj.data('speed'));

      // Put together our final background position
      var coords = '50% ' + yPos + 'px';

      // Move the background
      $bgobj.css({
        backgroundPosition: coords
      });

    }); // end window scroll

  });


  /* ---------------------------------------------
  jQuery for page scrolling feature - requires jQuery Easing plugin
  --------------------------------------------- */


  $('a.page-scroll').bind('click', function(event) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: ($($anchor.attr('href')).offset().top - 50)
    }, 1250, 'easeInOutExpo');
    event.preventDefault();
  });
  // Highlight the top nav as scrolling occurs
  // $('body').scrollspy({
  //   target: '.navbar',
  //   offset: 51
  // })
  // Offset for Main Navigation
  // $('.navbar').affix({
  //   offset: {
  //     top: 100
  //   }
  // })


  /* ---------------------------------------------
      Smooth scroll
  --------------------------------------------- */


  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });

  /* ---------------------------------------------
  For the sticky navigation
  --------------------------------------------- */


  $window.scroll(function() {
    var scroll = $window.scrollTop();

    if (scroll >= 100) {
      $('nav').addClass("sticky animated pulse");
    } else {
      $("nav").removeClass("sticky animated pulse");
    }
  });


  document.addEventListener('DOMContentLoaded', function () {

    // Get all "navbar-burger" elements
    var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
  
      // Add a click event on each of them
      $navbarBurgers.forEach(function ($el) {
        $el.addEventListener('click', function () {
  
          // Get the target from the "data-target" attribute
          var target = $el.dataset.target;
          var $target = document.getElementById(target);
  
          // Toggle the class on both the "navbar-burger" and the "navbar-menu"
          $el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
  
        });
      });
    }
  
  });


  /* ---------------------------------------------
      Animations
  --------------------------------------------- */

  $('.js--wp-1').addClass('opa-0').waypoint({
    handler: function() {
      $(this.element).addClass("opa-1 animated fadeInUp")
    },
    offset: '90%'
  });

  $('.js--wp-2').addClass('opa-0').waypoint({
    handler: function() {
      $(this.element).addClass("opa-1 animated zoomIn")
    },
    offset: '90%'
  });

  $('.js--wp-3').addClass('opa-0').waypoint({
    handler: function() {
      $(this.element).addClass("opa-1 animated slideInLeft")
    },
    offset: '80%'
  });

  $('.js--wp-4').addClass('opa-0').waypoint({
    handler: function() {
      $(this.element).addClass("opa-1 animated slideInRight")
    },
    offset: '80%'
  });

  $('.js--wp-5').addClass('opa-0').waypoint({
    handler: function() {
      $(this.element).addClass("opa-1 animated flipInY")
    },
    offset: '60%'
  });

  $('.js--wp-6').addClass('opa-0').waypoint({
    handler: function() {
      $(this.element).addClass("opa-1 animated fadeInUpBig")
    },
    offset: '80%'
  });

  $('.js--wp-7').addClass('opa-0').waypoint({
    handler: function() {
      $(this.element).addClass("opa-1 animated fadeInDownBig")
    },
    offset: '80%'
  });

  $('.js--wp-8').addClass('opa-0').waypoint({
    handler: function() {
      $(this.element).addClass("opa-1 animated pulse")
    },
    offset: '80%'
  });




    var $toggle = $('#nav-toggle');
    var $menu = $('#nav-menu');
  
    $toggle.click(function() {
      $(this).toggleClass('is-active');
      $menu.toggleClass('is-active');
    });
  
    $('.modal-button').click(function() {
      var target = $(this).data('target');
      $('html').addClass('is-clipped');
      $(target).addClass('is-active');
    });
  
    $('.modal-background, .modal-close').click(function() {
      $('html').removeClass('is-clipped');
      $(this).parent().removeClass('is-active');
    });
  
    $('.modal-card-head .delete, .modal-card-foot .button').click(function() {
      $('html').removeClass('is-clipped');
      $('#modal-ter').removeClass('is-active');
    });

    $('#tabs li').on('click', function() {
      var tab = $(this).data('tab');
  
      $('#tabs li').removeClass('is-active');
      $(this).addClass('is-active');
  
      $('#tab-content p').removeClass('is-active');
      $('p[data-content="' + tab + '"]').addClass('is-active');
    });
  


    // MODAL starts heres !

    $("#showModal").click(function() {
      $("#sounds").addClass("is-active");  
    });
    $("#showModal1").click(function() {
      $("#lights").addClass("is-active");  
    });
    $("#showModal2").click(function() {
      $("#sounds").addClass("is-active");  
    });
    $("#desk").click(function() {
      $("#tech").addClass("is-active");  
    });
 

    
    $(".modal-close").click(function() {
       $(".modal").removeClass("is-active");
    });
  
 
  

});

